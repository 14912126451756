import React, { useState } from "react";
import CustomModal from "../BrandingOverlayManager/Modal";
import { Card, CardContent, Checkbox, Typography } from "@mui/material";

import { COLORS } from "../../utils/colors";

const LoginSettings = ({
  modalVisible,
  handleCloseModal,
  savedSettings,
  setSavedSettings,
}) => {
  let socialSubmissionSettings = savedSettings;

  const [requireNameSocial, setRequireNameSocial] = useState(
    savedSettings ? savedSettings.requireNameSocial : true
  );
  const [requireEmailSocial, setRequireEmailSocial] = useState(
    savedSettings ? savedSettings.requireEmailSocial : false
  );
  const [requireAgeSocial, seRequireAgeSocial] = useState(
    savedSettings ? savedSettings.requireAgeSocial : false
  );
  const [requireNameGames, setRequireNameGames] = useState(
    savedSettings ? savedSettings.requireNameGames : true
  );
  const [requireEmailGames, setRequireEmailGames] = useState(
    savedSettings ? savedSettings.requireEmailGames : false
  );
  const [requireAgeGames, seRequireAgeGames] = useState(
    savedSettings ? savedSettings.requireAgeGames : false
  );
  const [requireNameLiveCam, setRequireNameLiveCam] = useState(
    savedSettings ? savedSettings.requireNameLiveCam : true
  );
  const [requireEmailLiveCam, setRequireEmailLiveCam] = useState(
    savedSettings ? savedSettings.requireEmailLiveCam : false
  );
  const [requireAgeLiveCam, seRequireAgeLiveCam] = useState(
    savedSettings ? savedSettings.requireAgeLiveCam : false
  );

  return (
    <CustomModal
      open={modalVisible}
      handleClose={() => {
        handleCloseModal();
      }}
      handleSubmit={() => {
        setSavedSettings(socialSubmissionSettings);
        handleCloseModal();
      }}
      title="Web App Login Settings"
    >
      <div className="advertParentDiv">
        <Card
          className="backgroundRoot"
          style={{ backgroundColor: COLORS.popupBackground }}
        >
          <CardContent>
            <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
              Social Submissions:
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "-5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "not-allowed",
                }}
                onClick={() => {
                  setRequireNameSocial(true);
                  socialSubmissionSettings.requireNameSocial = true;
                }}
              >
                <Checkbox
                  checked={requireNameSocial}
                  disabled
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Name
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setRequireEmailSocial(!requireEmailSocial);
                  socialSubmissionSettings.requireEmailSocial =
                    !requireEmailSocial;
                }}
              >
                <Checkbox
                  checked={requireEmailSocial}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Email
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  seRequireAgeSocial(!requireAgeSocial);
                  socialSubmissionSettings.requireAgeSocial = !requireAgeSocial;
                }}
              >
                <Checkbox
                  checked={requireAgeSocial}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Age
                </Typography>
              </div>
            </div>
            <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
              Games and Polls:
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "-5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "not-allowed",
                }}
                onClick={() => {
                  setRequireNameGames(true);
                  socialSubmissionSettings.requireNameGames = true;
                }}
              >
                <Checkbox
                  checked={requireNameGames}
                  disabled
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Name
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setRequireEmailGames(!requireEmailGames);
                  socialSubmissionSettings.requireEmailGames =
                    !requireEmailGames;
                }}
              >
                <Checkbox
                  checked={requireEmailGames}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Email
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  seRequireAgeGames(!requireAgeGames);
                  socialSubmissionSettings.requireAgeGames = !requireAgeGames;
                }}
              >
                <Checkbox
                  checked={requireAgeGames}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Age
                </Typography>
              </div>
            </div>
            <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
              Live Cam:
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "-5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "not-allowed",
                }}
                onClick={() => {
                  setRequireNameLiveCam(true);
                  socialSubmissionSettings.requireNameLiveCam = true;
                }}
              >
                <Checkbox
                  checked={requireNameLiveCam}
                  disabled
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Name
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setRequireEmailLiveCam(!requireEmailLiveCam);
                  socialSubmissionSettings.requireEmailLiveCam =
                    !requireEmailLiveCam;
                }}
              >
                <Checkbox
                  checked={requireEmailLiveCam}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Email
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  seRequireAgeLiveCam(!requireAgeLiveCam);
                  socialSubmissionSettings.requireAgeLiveCam =
                    !requireAgeLiveCam;
                }}
              >
                <Checkbox
                  checked={requireAgeLiveCam}
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: COLORS.primary,
                    },
                  }}
                />
                <Typography style={{ color: COLORS.textLabel, fontSize: 14 }}>
                  Age
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </CustomModal>
  );
};

export default LoginSettings;
