import { useEffect, useRef } from "react";
import "./SwipeableTabs.css";
const Tab = ({ children }) => {
  return <div className="st-tab">{children}</div>;
};
const SwipeableTabs = ({ children, onTabChange, activeTabIndex }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollLeft = activeTabIndex * container.clientWidth;
    }
  }, [activeTabIndex]);
  useEffect(() => {
    let timeoutId;
    const onScollStopped = (e) => {
      const scrollLeft = e.target.scrollLeft;
      const clientWidth = e.target.clientWidth;
      const activeTabIndex = Math.round(scrollLeft / clientWidth);
      onTabChange(activeTabIndex);
    };
    const onScroll = (e) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(onScollStopped, 100, e);
    };
    const cleanup = () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", onScroll, false);
      }
    };
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", onScroll, false);
    }
    return cleanup;
  }, []);
  return (
    <div className="st-container" ref={containerRef}>
      {children.map((child, index) => {
        return <Tab key={index}>{child}</Tab>;
      })}
    </div>
  );
};
export default SwipeableTabs;
