import { memo } from "react";
import TwoToneSplit from "./TwoToneSplit";
import TwoColorOpacity from "./TwoColorOpacity";
import SingleColorOpacity from "./SingleColorOpacity";
import RandomColorStrobe from "./RandomColorStrobe";
import SolidColor from "./SolidColor";
import ThreeColorOpacity from "./ThreeColorOpacity";

const LightShowEffects = memo(
  ({
    effect,
    color,
    color2,
    color3,
    color1HoldTime,
    color2HoldTime,
    animationDuration,
    switchOffFlashlight,
  }) => {
    if (switchOffFlashlight) {
      switchOffFlashlight();
    }

    return (
      <>
        {effect === 1 ? (
          <TwoToneSplit
            color={color}
            color2={color2}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
        {effect === 2 ? (
          <SingleColorOpacity
            color={color}
            color2={color2}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
        {effect === 3 ? (
          <TwoColorOpacity
            color={color}
            color2={color2}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
        {effect === 4 ? (
          <RandomColorStrobe
            color={color}
            color2={color2}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
        {effect === 5 ? <SolidColor color={color} /> : null}
        {effect === 6 ? (
          <ThreeColorOpacity
            color={color}
            color2={color2}
            color3={color3}
            color1HoldTime={color1HoldTime}
            color2HoldTime={color2HoldTime}
            animationDuration={animationDuration}
          />
        ) : null}
      </>
    );
  }
);
export default LightShowEffects;
