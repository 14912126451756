import React, { useContext, useEffect, useState } from "react";
import Modal from "./Modal";
import { Card, CardContent, Link, TextField, Typography } from "@mui/material";
import "./styles.css";
import { AppContext } from "../../../contexts/App.Context";
import { COLORS } from "../../../utils/colors";
import { TABS } from "../constants";
import { getItemFromLocalStorage, isValidEmail } from "../../../utils/utility";
import { WEB_APP_LOGIN_KEY } from "../../../constants";

export default (function ({
  modalVisible,
  event,
  handleContinue,
  initialUsername,
  page,
}) {
  const appContext = useContext(AppContext);
  const getDefaultValue = (key) => {
    const localStorageSavedObject = getItemFromLocalStorage(WEB_APP_LOGIN_KEY);
    if (localStorageSavedObject && localStorageSavedObject[key]) {
      return localStorageSavedObject[key];
    } else {
      return "";
    }
  };
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState(getDefaultValue("email"));
  const [age, setAge] = useState(getDefaultValue("age"));
  useEffect(() => {
    setUsername(initialUsername);
  }, [initialUsername]);

  const showEmailField = () => {
    if (!page) {
      return false;
    }
    if (page.includes("social") && event?.loginSettings?.requireEmailSocial) {
      return true;
    } else if (page === TABS.GAMES && event?.loginSettings?.requireEmailGames) {
      return true;
    } else if (
      page === TABS.LIVE &&
      event?.loginSettings?.requireEmailLiveCam
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showAgeField = () => {
    if (!page) {
      return false;
    }
    if (page.includes("social") && event?.loginSettings?.requireAgeSocial) {
      return true;
    } else if (page === TABS.GAMES && event?.loginSettings?.requireAgeGames) {
      return true;
    } else if (page === TABS.LIVE && event?.loginSettings?.requireAgeLiveCam) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    if (username.trim() === "") {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Please enter your name.",
      });
      return;
    }
    if (showEmailField()) {
      if (!isValidEmail(email.trim())) {
        appContext.triggerToast(true, {
          type: "warning",
          message: "Please enter your valid email id.",
        });
        return;
      }
    }
    if (showAgeField()) {
      if (age.trim() === "") {
        appContext.triggerToast(true, {
          type: "warning",
          message: "Please enter your age.",
        });
        return;
      }
    }
    handleContinue(username, email, age);
  };

  return (
    <Modal open={modalVisible} event={event} handleContinue={onSubmit}>
      <div className="gupParentDiv">
        <Card className="gupRoot">
          <CardContent className="adsCardContent">
            <Typography sx={{ my: 1, width: "100%" }}>
              Full Name
              <span
                style={{
                  color: COLORS.error,
                }}
              >
                {" *"}
              </span>
            </Typography>
            <TextField
              size="small"
              variant="filled"
              hiddenLabel
              inputProps={{
                maxLength: 20,
              }}
              fullWidth
              defaultValue={initialUsername}
              autoFocus
              id="guestusername"
              InputLabelProps={{ style: { color: COLORS.textHeader } }}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />

            {showEmailField() && (
              <>
                <Typography sx={{ my: 1, width: "100%" }}>
                  Email
                  <span
                    style={{
                      color: COLORS.error,
                    }}
                  >
                    {" *"}
                  </span>
                </Typography>
                <TextField
                  type="email"
                  size="small"
                  variant="filled"
                  hiddenLabel
                  inputProps={{
                    maxLength: 40,
                  }}
                  fullWidth
                  defaultValue={getDefaultValue("email")}
                  autoFocus
                  id="guestuseremail"
                  InputLabelProps={{ style: { color: COLORS.textHeader } }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </>
            )}
            {showAgeField() && (
              <>
                <Typography sx={{ my: 1, width: "100%" }}>
                  Age
                  <span
                    style={{
                      color: COLORS.error,
                    }}
                  >
                    {" *"}
                  </span>
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  variant="filled"
                  hiddenLabel
                  inputProps={{
                    maxLength: 3
                  }}
                  fullWidth
                  defaultValue={getDefaultValue("age")}
                  autoFocus
                  id="guestuserage"
                  InputLabelProps={{ style: { color: COLORS.textHeader } }}
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      setAge(e.target.value.substring(0, 2));
                    } else {
                      setAge(e.target.value);
                    }
                  }}
                />
              </>
            )}
            <Typography
              className="privacyInfo"
              style={{ opacity: 0.7, fontSize: 12, marginTop: "24px" }}
            >
              {"By continuing you agree to\nUvenu LLC’s "}
              <Link
                style={{ color: "blue" }}
                href={"https://www.uvenu.com/uvenu-terms-and-conditions"}
                target="_blank"
                underline="hover"
              >
                Privacy Policy
              </Link>
              {" and "}
              <Link
                style={{ color: "blue" }}
                href={"https://www.uvenu.com/uvenu-terms-and-conditions"}
                target="_blank"
                underline="hover"
              >
                Terms
              </Link>
              {"."}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Modal>
  );
});
