import React, { useEffect, useRef, useState } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { COLORS } from "../../utils/colors";
import {
  Edit,
  RestartAlt,
  Delete,
  CopyAll,
  LeaderboardRounded,
} from "@mui/icons-material";
import { GET_GAME_RESULT_POLLING_INTERVAL } from "../../constants";
import { getGameStats } from "./networkCalls/networkCalls";
import ConfirmationDialog from "../../components/UI/ConfirmationDialog";
const GameItem = ({
  game,
  selectedGameData,
  setSelectedGameData,
  handleEditGame,
  handleCloneGame,
  handleDeleteGame,
  handleResetGameAnswers,
  handleRevealResult,
  pollingInterval,
}) => {
  const [totalVotes, setTotalVotes] = useState(0);
  const intervalId = useRef(null);
  const [showRevealConfirmation, setShowRevealConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  useEffect(() => {
    if (game?._id) {
      async function fetchGameStats() {
        const response = await getGameStats(game._id);
        if (response && response.totalRespondents) {
          setTotalVotes(response.totalRespondents);
        } else {
          setTotalVotes(0);
        }
      }
      if (game.isActive) {
        fetchGameStats();
        intervalId.current = setInterval(
          () => {
            fetchGameStats();
          },
          pollingInterval
            ? pollingInterval * 1000
            : GET_GAME_RESULT_POLLING_INTERVAL
        );
      } else {
        clearInterval(intervalId.current);
      }
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [game?._id, pollingInterval, game.isActive]);
  return (
    <div
      className={`qcs-list-item${
        selectedGameData && selectedGameData._id === game._id ? " selected" : ""
      }`}
      onClick={(e) => {
        setSelectedGameData(game);
      }}
    >
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ color: COLORS.textHeader }}>
          {game.gameTitle}
        </Typography>
        <div>
          <Typography
            style={{
              fontSize: 12,
              padding: 5,
              margin: "8px",
              borderRadius: 5,
              color: COLORS.textHeader,
              backgroundColor: "rgba(25, 61, 188, 0.25)",
              display: "inline-block",
            }}
          >
            {totalVotes} Vote{totalVotes > 1 ? "s" : ""}
          </Typography>

          {game.isActive && (
            <Typography
              style={{
                fontSize: 12,
                padding: 5,
                borderRadius: 5,
                marginRight: "8px",
                color: COLORS.completedColor,
                backgroundColor: COLORS.completedBackground,
                display: "inline-block",
              }}
            >
              Active
            </Typography>
          )}
          {game.gameType === "2" && !game.isLive && game.isActive && (
            <IconButton onClick={() => setShowRevealConfirmation(true)}>
              <Tooltip title="Reveal Resutls" arrow>
                <LeaderboardRounded />
              </Tooltip>
            </IconButton>
          )}
          <IconButton onClick={(e) => handleEditGame(game)}>
            <Tooltip title="Edit">
              <Edit />
            </Tooltip>
          </IconButton>
          <IconButton onClick={(e) => handleCloneGame(game)}>
            <Tooltip title="Clone">
              <CopyAll />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={async () => {
              handleResetGameAnswers(game);
              const response = await getGameStats(game._id);
              if (response && response.totalRespondents) {
                setTotalVotes(response.totalRespondents);
              } else {
                setTotalVotes(0);
              }
            }}
          >
            <Tooltip title={"Reset"}>
              <RestartAlt />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => setShowDeleteConfirmation(true)}>
            <Tooltip title={"Delete"}>
              <Delete />
            </Tooltip>
          </IconButton>
        </div>
      </div>
      <ConfirmationDialog
        title="Reveal Poll Results Confirmation"
        confirmButtonText="Yes Reveal"
        message={`Are you sure you want to reveal poll results for ${
          selectedGameData?.gameTitle || ""
        }?`}
        show={showRevealConfirmation}
        onConfirm={() => {
          handleRevealResult(game);
          setShowRevealConfirmation(false);
        }}
        onClose={() => {
          setShowRevealConfirmation(false);
        }}
      />
      <ConfirmationDialog
        title="Delete Confirmation"
        confirmButtonText="Yes Delete"
        message={`Are you sure you want to delete ${
          selectedGameData?.gameTitle || ""
        }?`}
        show={showDeleteConfirmation}
        onConfirm={() => {
          handleDeleteGame(game);
          setShowDeleteConfirmation(false);
        }}
        onClose={() => {
          setShowDeleteConfirmation(false);
        }}
      />
    </div>
  );
};
export default GameItem;
