import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import { COLORS } from "../../utils/colors";
import "./styles.css";

export default function CustomModal(props) {
  const { open, handleClose, title, handleSubmit, children } = props;

  return (
    <Dialog maxWidth={"lg"} open={open}>
      <Paper style={{ backgroundColor: COLORS.popupBackground, minWidth: "300px" }}>
        <DialogTitle style={{ color: COLORS.textHeader }}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            style={{
              backgroundColor: "transparent",
              color: COLORS.primary,
              borderColor: COLORS.primary,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="doneButton"
            style={{
              color: COLORS.textHeader,
              backgroundColor: COLORS.primary,
              borderColor: COLORS.primary,
              "&:hover": {
                color: COLORS.primary,
              },
            }}
            onClick={handleSubmit}
          >
            Done
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
}
