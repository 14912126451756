import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { SUBMITTED_GAME_IDS_KEY, URLS } from "../../../constants";
import { uploader } from "../../../utils/uploader";
import {
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "../../../utils/utility";

async function getAllGameData(eventId, loggedInUserObj) {
  try {
    const response = await uvenuFetcher({
      method: "GET",
      url: URLS.GET_ALL_GAME_DATA(eventId),
      headers: {
        token: `${loggedInUserObj.token}`,
      },
    });
    if (response.statusCode === 200) {
      return response.json.games;
    } else {
      return [];
    }
  } catch {}
}

//still need to be integrated.
async function getActiveGameData(eventId, userId) {
  try {
    const response = await uvenuFetcher({
      method: "GET",
      url: URLS.GET_ACTIVE_GAME(eventId, userId),
    });
    return response;
  } catch {}
}

async function createGame(gameObj, loggedInUserObj) {
  try {
    const response = await uvenuFetcher({
      method: "POST",
      url: URLS.CREATE_GAME,
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: gameObj,
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}

async function updateGame(gameObj, loggedInUserObj) {
  try {
    const response = await uvenuFetcher({
      method: "PATCH",
      url: URLS.PATCH_GAME, // Replace with the appropriate URL for updating a game
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: gameObj, // Replace with the actual game object data
    });
    if (response.statusCode === 200) {
      return response.json.message;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}
async function deleteGameAnswers(gameId, loggedInUserObj) {
  try {
    const response = await uvenuFetcher({
      method: "DELETE",
      url: URLS.DELETE_GAME_ANSWERS(gameId),
      headers: {
        token: `${loggedInUserObj.token}`,
      },
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}

async function cloneGame(gameId, loggedInUserObj) {
  try {
    const response = await uvenuFetcher({
      method: "POST",
      url: URLS.CLONE_GAME,
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: { gameId },
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}

async function deleteGameData(gameId, loggedInUserObj) {
  try {
    const response = await uvenuFetcher({
      method: "DELETE",
      url: URLS.DELETE_GAME(gameId),
      headers: {
        token: `${loggedInUserObj.token}`,
      },
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}

async function gameSubmit(gameObj, lastResetTime) {
  try {
    const response = await uvenuFetcher({
      method: "POST",
      url: URLS.SUBMIT_GAME_ANSWER,
      body: gameObj,
    });
    if (response.statusCode === 200) {
      saveGameIdToStorage(gameObj.gameId, lastResetTime);
      return response.json.data;
    } else {
      return response.json.reason;
    }
  } catch (error) {
    return null; // Return null or handle the error as needed
  }
}

const saveGameIdToStorage = (gameId, lastResetTime) => {
  const gamesStateArrya = getItemFromLocalStorage(SUBMITTED_GAME_IDS_KEY);
  if (gamesStateArrya) {
    const thisGame = gamesStateArrya.find((game) => game.gameId === gameId);
    if (thisGame) {
      thisGame.lastResetTime = lastResetTime;
    } else {
      gamesStateArrya.push({ gameId, lastResetTime });
    }
    saveItemToLocalStorage(SUBMITTED_GAME_IDS_KEY, gamesStateArrya);
  } else {
    const newGamesArray = [{ gameId, lastResetTime }];
    saveItemToLocalStorage(SUBMITTED_GAME_IDS_KEY, newGamesArray);
  }
};

async function getGameStats(gameId) {
  try {
    const response = await uvenuFetcher({
      method: "GET",
      url: URLS.GET_GAME_STATS(gameId),
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}

async function getGameResultsScreenSettings(gameId) {
  try {
    const response = await uvenuFetcher({
      method: "GET",
      url: URLS.GET_GAME_SCREEN_SETTINGS(gameId),
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}

const saveLightShowSettings = async (
  payload,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) => {
  try {
    const response = await uvenuFetcher({
      method: "POST",
      url: URLS.SAVE_LIGHT_SHOW_SETTINGS,
      headers: { token: loggedInUserObj.token },
      body: payload,
    });
    if (response.statusCode === 200) {
      return response.json.message;
    } else {
      return "error: " + response.json.message;
    }
  } catch (error) {
    console.log(error);
  }
};

async function getLightShowSettingsForEvent(eventId) {
  try {
    const response = await uvenuFetcher({
      method: "GET",
      url: URLS.GET_LIGHT_SHOW_SETTINGS(eventId),
    });
    if (response.statusCode === 200) {
      return response.json.lightShowSettings;
    } else {
      return [];
    }
  } catch {}
}

async function getStreamingLightShow(eventId) {
  try {
    const response = await uvenuFetcher({
      method: "GET",
      url: URLS.GET_STREAMING_LIGHT_SHOW(eventId),
    });
    if (response.statusCode === 200) {
      return response.json.show;
    } else {
      return [];
    }
  } catch {}
}

async function createLightShow(
  showObj,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) {
  try {
    const response = await uvenuFetcher({
      method: "POST",
      url: URLS.CREATE_LIGHT_SHOW,
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: showObj,
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}
async function updateLightShow(
  showObj,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) {
  try {
    const response = await uvenuFetcher({
      method: "PATCH",
      url: URLS.PATCH_LIGHT_SHOW, // Replace with the appropriate URL for updating a game
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: showObj, // Replace with the actual game object data
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}
async function getLightShowsForEvent(
  eventId,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) {
  try {
    const response = await uvenuFetcher({
      method: "GET",
      url: URLS.GET_LIGHT_SHOWS(eventId),
      headers: {
        token: `${loggedInUserObj.token}`,
      },
    });
    if (response.statusCode === 200) {
      return response.json.lightShows;
    } else {
      return [];
    }
  } catch {}
}

const deleteLightShow = async (
  showId,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) => {
  try {
    const response = await uvenuFetcher({
      method: "DELETE",
      url: URLS.DELETE_LIGHT_SHOW(showId),
      headers: { token: loggedInUserObj.token },
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch (error) {
    console.log(error);
  }
};

const createSuperScreenLayout = async (
  layout,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) => {
  try {
    const response = await uploader({
      url: URLS.CREATE_SUPER_SCREEN_LAYOUT,
      body: layout,
      headers: { token: loggedInUserObj.token },
    });

    if (response.statusCode === 200) {
      return response.json;
    } else {
      return "error: " + response.json.message;
    }
  } catch (error) {
    console.log(error);
  }
};
const updateSuperScreenLayout = async (
  layout,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) => {
  try {
    const response = await uploader({
      url: URLS.PATCH_SUPER_SCREEN_LAYOUT,
      body: layout,
      method: "PATCH",
      headers: {
        token: `${loggedInUserObj.token}`,
      },
    });
    if (response.statusCode === 200) {
      return response.json;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
};

const getAllSuperScreenLayouts = async (eventId) => {
  try {
    const response = await uvenuFetcher({
      method: "GET",
      url: URLS.GET_SUPER_SCREEN_LAYOUTS(eventId),
    });
    if (response.statusCode === 200) {
      return response.json.layouts;
    } else {
      return [];
    }
  } catch {}
};
const deleteSuperScreenLayout = async (
  layoutId,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) => {
  try {
    const response = await uvenuFetcher({
      method: "DELETE",
      url: URLS.DELETE_SUPER_SCREEN_LAYOUT(layoutId),
      headers: { token: loggedInUserObj.token },
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch (error) {
    console.log(error);
  }
};

const postQuote = async (payload) => {
  try {
    const response = await uvenuFetcher({
      method: "POST",
      url: URLS.SEND_QUOTE,
      body: payload,
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
};
const reviewQuote = async (
  payload,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) => {
  try {
    const response = await uvenuFetcher({
      method: "PATCH",
      url: URLS.REVIEW_QUOTE,
      headers: {
        token: `${loggedInUserObj.token}`,
      },
      body: payload,
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
};

async function deleteEventContacts(
  eventId,
  loggedInUserObj = JSON.parse(localStorage.getItem("currentUser"))
) {
  try {
    const response = await uvenuFetcher({
      method: "DELETE",
      url: URLS.DELETE_EVENT_CONTACTS(eventId),
      headers: {
        token: `${loggedInUserObj.token}`,
      },
    });
    if (response.statusCode === 200) {
      return response.json.data;
    } else {
      return "error: " + response.json.message;
    }
  } catch {}
}

export {
  createGame,
  updateGame,
  getActiveGameData,
  getAllGameData,
  deleteGameData,
  gameSubmit,
  getGameStats,
  getGameResultsScreenSettings,
  deleteGameAnswers,
  saveLightShowSettings,
  getLightShowSettingsForEvent,
  getStreamingLightShow,
  createLightShow,
  updateLightShow,
  getLightShowsForEvent,
  deleteLightShow,
  createSuperScreenLayout,
  getAllSuperScreenLayouts,
  deleteSuperScreenLayout,
  updateSuperScreenLayout,
  postQuote,
  reviewQuote,
  cloneGame,
  deleteEventContacts
};
