import "../styles.css";

import { TABS } from "../constants";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useEffect, useState } from "react";
import {
  AppShortcutRounded,
  PollRounded,
  SendRounded,
  VideocamRounded,
  WebStoriesRounded,
} from "@mui/icons-material";

export function showBottomTabStatus(title, pageParams) {
  const initialPage = pageParams.page;
  const loadWithOptions = pageParams.loadWithOptions;
  if (
    initialPage === null ||
    initialPage === "" ||
    title.includes(initialPage)
  ) {
    if (loadWithOptions === "true") {
      if (
        initialPage === null ||
        initialPage === "" ||
        initialPage === "live" ||
        title.includes(TABS.SOCIAL)
      ) {
        if (title === "games") {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export default function BottomTabs({
  currentTab,
  pageParams,
  setCurrentTab,
  eventData,
}) {
  const [value, setValue] = useState(currentTab);
  useEffect(() => {
    const tabs = document.querySelectorAll(".social-bottomTab");
    tabs.forEach((tab, i) => {
      if (tab.id === currentTab) {
        tab.click();
      }
    });
  }, [currentTab]);
  const handleChange = (event, newValue) => {
    if (eventData === null) {
      alert(
        "To participate in the event experience, you must be at the event location."
      );
      return;
    }
    setValue(newValue);
    const tabs = document.querySelectorAll(".social-bottomTab");
    setCurrentTab(tabs[newValue].id);
  };
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
      aria-label="icon label tabs"
      TabIndicatorProps={{
        style: { height: "4px", borderRadius: "20px" },
      }}
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      {showBottomTabStatus(TABS.GAMES, pageParams) && (
        <Tab
          className="social-bottomTab"
          icon={<PollRounded />}
          id={TABS.GAMES}
        />
      )}
      {showBottomTabStatus(TABS.LIVE, pageParams) && (
        <Tab
          className="social-bottomTab"
          icon={<VideocamRounded />}
          id={TABS.LIVE}
        />
      )}
      {showBottomTabStatus(TABS.SOCIAL_FEED, pageParams) && (
        <Tab
          className="social-bottomTab"
          icon={<WebStoriesRounded />}
          id={TABS.SOCIAL_FEED}
        />
      )}
      {showBottomTabStatus(TABS.SOCIAL_SEND, pageParams) && (
        <Tab
          className="social-bottomTab"
          icon={<SendRounded />}
          id={TABS.SOCIAL_SEND}
        />
      )}
      {showBottomTabStatus(TABS.LIGHT_SHOW, pageParams) && (
        <Tab
          className="social-bottomTab"
          icon={<AppShortcutRounded />}
          id={TABS.LIGHT_SHOW}
        />
      )}
    </Tabs>
  );
}
