import { useState, useEffect, useMemo, useContext, useRef } from "react";

import "../styles.css";
import TopBar from "./TopBar";
import GuestUsernamePopup from "../GuestUsernamePopup";
import BottomTabs from "./BottomTabs";
import { ONLINE_PRESENCE_INTERVAL, TABS } from "../constants";
import { GamesTab } from "../GamesTab";
import { LiveTab } from "../LiveTab";
import { SocialFeed } from "../SocialFeedTab";
import { SocialSend } from "../SocialSendTab";
import powered_by_logo from "../../../assets/powered_by_logo.png";
import { CAPTURE_SOURCES, URLS, WEB_APP_LOGIN_KEY } from "../../../constants";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { useParamsFromUrlFragment } from "./useParamsFromUrlFragmet";
import generateUserid from "../../../utils/generateUserid";
import { insights } from "../../../ApplicationInsightsService";
import Loader from "../../../components/Loader/Loader";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import muted from "../../../../src/assets/muted.png";
import unmuted from "../../../../src/assets/unmuted.png";
import {
  formatHyperlink,
  isValidEmail,
  saveItemToLocalStorage,
} from "../../../utils/utility";
import { AppContext } from "../../../contexts/App.Context";
import {
  AD_TYPES,
  getFullscreenAdHeaderText,
  getFullscreenAdSubHeaderText,
  getFullscreenAdPosition,
} from "../../AdsSetupManager/functions";
import { COLORS } from "../../../utils/colors";
import LightShowMobileWeb from "../../LightShow/LightShowMobileWeb";
import QuotesSend from "../Quotes";
import SwipeableTabs from "../../../components/UI/SwipeableTabs";

let restartSession = () => {};
let cameraNotLoaded = false;
function RenderSelectedPage({
  currentTab,
  pageParams,
  eventData,
  userName,
  userIdFromDB,
  usernamePopupVisible,
  isPortrait,
  advertsData,
  stopCameraFeed,
  handleScroll,
}) {
  switch (currentTab) {
    case TABS.GAMES:
      return (
        <GamesTab
          pageParams={pageParams}
          eventData={eventData}
          userId={userIdFromDB}
          advertsData={advertsData}
        />
      );
    case TABS.LIVE:
      return (
        <LiveTab
          userName={userName}
          pageParams={pageParams}
          eventData={eventData}
          userIdFromDB={userIdFromDB}
          showSignalling={!usernamePopupVisible && eventData}
          isPortrait={isPortrait}
          restartSession={restartSession}
          cameraNotLoaded={(status) => {
            cameraNotLoaded = status;
          }}
          advertsData={advertsData}
          stopCameraFeed={stopCameraFeed}
        />
      );
    case TABS.SOCIAL_FEED:
      return (
        <SocialFeed
          eventData={eventData}
          userId={userIdFromDB}
          advertsData={advertsData}
          isPortrait={isPortrait}
          onSocialFeedScroll={handleScroll}
        />
      );
    case TABS.SOCIAL_SEND:
      return (
        <SocialSend
          userId={userIdFromDB}
          userName={userName}
          eventData={eventData}
          loadwithoptions={pageParams.loadWithOptions}
          isPortrait={isPortrait}
          advertsData={advertsData}
        />
      );
    case TABS.LIGHT_SHOW:
      return (
        <LightShowMobileWeb
          userId={userIdFromDB}
          userName={userName}
          eventData={eventData}
          isPortrait={isPortrait}
          advertsData={advertsData}
        />
      );
    case TABS.QUOTE_SEND:
      return (
        <QuotesSend
          userId={userIdFromDB}
          userName={userName}
          eventData={eventData}
          advertsData={advertsData}
        />
      );
    default:
      return <div>Here goes backup content</div>;
  }
}

let onlinePresenceInterval = null;
let databaseUniqueUserID = "";
async function postOnlinePresence({
  userIdForSession,
  userName,
  eventId,
  databaseUniqueUserID,
  sourceName,
}) {
  const url = URLS.ONLINE_PRESENCE(
    userIdForSession,
    userName,
    eventId,
    databaseUniqueUserID,
    sourceName
  );
  return await uvenuFetcher({
    url,
    method: "GET",
    timeout: 3000,
  });
}

function getSourceName(pageParams) {
  const { loadWithOptions, eventId } = pageParams;
  let sourceName = "geo";
  if (loadWithOptions === "true") {
    sourceName = "production";
  } else if (eventId === null || eventId === "") {
    sourceName = "geo";
  } else {
    sourceName = "vip";
  }
  return sourceName;
}

function getCurrentTabValue(pageValue) {
  if (pageValue) {
    if (pageValue.length > 0) {
      if (pageValue === TABS.SOCIAL) {
        return TABS.SOCIAL_SEND;
      }
      return pageValue;
    }
  } else {
    return TABS.LIVE;
  }
}

let postliveAd = null;
export default function JoinEventLive() {
  const appContext = useContext(AppContext);
  const pageParams = useParamsFromUrlFragment();
  const [currentTab, setCurrentTab] = useState(
    getCurrentTabValue(pageParams.page)
  );

  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [usernamePopupVisible, setUsernamePopupVisible] = useState(false);
  const [currentUserName, setCurrentUserName] = useState("");

  const userIdForSession = useMemo(() => generateUserid(), []);
  const [userIdFromDB, setUserIdFromDB] = useState(null);

  const [isPortrait, setIsPortrait] = useState(false);

  const lastScrollY = useRef(0);

  const handleScroll = (e) => {
    const headerElement = document.getElementById("mobile-web-app-header");
    const footerElement = document.getElementById("mobile-web-app-footer");
    if (e.scrollOffset > lastScrollY.current) {
      // Scrolling down
      if (headerElement) {
        headerElement.classList.add("hideHeader");
      }
      if (footerElement) {
        footerElement.classList.add("hideFooter");
      }
    } else {
      // Scrolling up
      if (headerElement) {
        headerElement.classList.remove("hideHeader");
      }
      if (footerElement) {
        footerElement.classList.remove("hideFooter");
      }
    }
    lastScrollY.current = e.scrollOffset;
  };

  useEffect(() => {
    setLoading(true);
    if (pageParams.eventId) {
      getEventInfoForVIPLink(pageParams.eventId);
    } else {
      loadLocationSettingsForGeoLink();
    }
  }, [pageParams.eventId]);

  const getEventInfoForVIPLink = async (eventID) => {
    try {
      setLoading(true);
      const response = await uvenuFetcher({
        url: URLS.EVENT_INFO(eventID),
        method: "GET",
      });
      setLoading(false);
      if (response.statusCode === 200) {
        updateEventSettingsAPI(eventID, response.json.data);
      } else {
        appContext.triggerToast(true, {
          type: "error",
          message:
            "Event not found with provided link. Please contact administrator.",
        });
        let error = Error(response.json.message);
        insights.trackUvenuException(error, {
          status: "error",
          info: "JoinEventLive: Event not found with eventid provided in vip link",
        });
      }
    } catch (error) {
      setLoading(false);
      insights.trackUvenuException(error, {
        status: "error",
        info: "JoinEventLive: Unhandled error in finding event with eventid provided in vip link",
      });
    }
  };

  const loadLocationSettingsForGeoLink = () => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getEventInfoForGeoLink(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log("not getting location: ", error);
          let message =
            "To participate in the event experience, you must allow location services. Please review and restart the session.\n";
          if (window.navigator.platform.toLowerCase().startsWith("li")) {
            message =
              message +
              "Go to Settings >> Chrome/Browser >> Search for Location Services >> set permission to Allow";
          } else {
            message =
              message +
              '-Open "Settings."\n-Go to "Privacy & Security."\n-Enable "Location Services."\n-Find "Safari/Chrome" on the list.\n-Set access to "While Using the App."';
          }
          alert(message);
          insights.trackUvenuException(error, {
            status: "error",
            info: "JoinEventLive: Exception in fetching location from device due to permissions",
            error: error,
          });
        }
      );
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "JoinEventLive: Unhandled Exception in fetching location from device due to permissions",
        error: error,
      });
    }
  };

  async function getEventInfoForGeoLink(latitude, longitude) {
    const eventResponse = await uvenuFetcher({
      url: URLS.GET_NEARBY_EVENT(latitude, longitude),
      method: "GET",
    });
    setLoading(false);
    if (eventResponse.statusCode === 200) {
      getEventInfoForVIPLink(eventResponse.json.data.event._id);
    } else {
      appContext.triggerToast(true, {
        type: "error",
        message:
          "To participate in the event experience, you must be at the event location.",
      });
      let error = Error(eventResponse.json.message);
      insights.trackUvenuException(error, {
        status: "failed",
        message: eventResponse.json.message,
        coordinates: {
          latitude: latitude,
          longitude: longitude,
        },
      });
    }
  }

  const updateEventSettingsAPI = async (eventID, eventJson) => {
    try {
      const eventResponse = await uvenuFetcher({
        url: URLS.EVENT_SETTINGS(eventID),
        method: "GET",
      });
      if (eventResponse.statusCode === 200) {
        updateEventInfo({ ...eventJson, ...eventResponse.json.data });
      } else {
        let error = Error(eventResponse.json.message);
        insights.trackUvenuException(error, {
          status: "failed",
          info: "JoinEventLive Page: Error while fetching event settings",
          message: eventResponse.json.message,
        });
      }
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "JoinEventLive Page: Exception while fetching event settings",
        error: error,
      });
    }
  };

  function updateEventInfo(eventJson) {
    setEventData(eventJson);
    const user_name = localStorage.getItem("web_connect_user_name");
    if (user_name && user_name === "Lightshow Audience") {
      localStorage.setItem("web_connect_user_name", "");
      user_name = "";
    }
    if (user_name) setCurrentUserName(user_name);
    if (currentTab !== TABS.LIGHT_SHOW) {
      setUsernamePopupVisible(true);
    } else {
      onContinue("Lightshow Audience");
    }
    fetchAndUpdateAds(eventJson._id);
  }

  useEffect(() => {
    if (eventData === null) return;
    if (usernamePopupVisible === true) {
      if (pageParams.username != null && pageParams.username.length > 0) {
        setCurrentUserName(pageParams.username);
        setUsernamePopupVisible(false);
      }
      return;
    }
    if (currentTab === TABS.LIVE && userIdFromDB === null) {
      onlineSubscriberFactory();
      return () => {
        clearInterval(onlinePresenceInterval);
      };
    }
  }, [usernamePopupVisible]);

  const onlineSubscriberFactory = () => {
    if (
      pageParams.loadWithOptions !== "true" &&
      currentTab === TABS.LIVE &&
      eventData.userStreamsAllowed === false
    ) {
      if (postliveAd) stopOnlineAndShowFullScreenAd();
      appContext.triggerToast(true, {
        type: "error",
        message: "Event is not live yet",
      });
      return;
    }

    onlinePresenceInterval = setInterval(async () => {
      if (eventData === null) return;
      let loginUsername =
        currentUserName.length > 0 ? currentUserName : "Web Guest";
      const response = await postOnlinePresence({
        userIdForSession,
        userName: loginUsername,
        eventId: eventData._id,
        databaseUniqueUserID: databaseUniqueUserID,
        sourceName: getSourceName(pageParams),
      });
      if (response.statusCode === 200) {
        const onlineResponse = response.json;
        if (onlineResponse.userStreamsAllowed === false) {
          stopOnlineAndShowFullScreenAd();
          return;
        }
        if (databaseUniqueUserID !== onlineResponse.eventUserId) {
          databaseUniqueUserID = onlineResponse.eventUserId;
          setUserIdFromDB(onlineResponse.eventUserId);
        }
      } else if (response.statusCode === 503) {
        clearInterval(onlinePresenceInterval);
        const waitingInterval = setInterval(async () => {
          const response2 = await postOnlinePresence({
            userIdForSession,
            userName: loginUsername,
            eventId: eventData._id,
            databaseUniqueUserID: databaseUniqueUserID,
            sourceName: getSourceName(pageParams),
          });
          if (response2.statusCode === 200) {
            const onlineResponse = response2.json;
            if (onlineResponse.userStreamsAllowed === false) {
              clearInterval(waitingInterval);
              stopOnlineAndShowFullScreenAd();
              return;
            }
            if (databaseUniqueUserID !== onlineResponse.eventUserId) {
              clearInterval(waitingInterval);
              databaseUniqueUserID = onlineResponse.eventUserId;
              onlineSubscriberFactory();
            }
          }
        }, 30000);
      } else {
        let error = Error(response.json.message);
        insights.trackUvenuException(error, {
          info: "JoinEventLive: error in updating user online",
          status: "failed",
          response: response.json,
          inputs: {
            userId: userIdForSession,
            username: loginUsername,
            eventId: eventData._id,
            databaseUniqueUserID: databaseUniqueUserID,
          },
        });
      }
    }, ONLINE_PRESENCE_INTERVAL);
  };

  const stopOnlineAndShowFullScreenAd = () => {
    if (onlinePresenceInterval !== null) {
      clearInterval(onlinePresenceInterval);
      onlinePresenceInterval = null;
    }
    showFullscreenAdToggle();
  };

  useEffect(() => {
    updateOrinetation();
    setIsPortrait(!isPortrait);
    window.addEventListener("orientationchange", updateOrinetation);
    if (process.env.NODE_ENV !== "development") {
      window.addEventListener("blur", onBlur, false);
    } else {
      console.log("skipping blur events in development mode.");
    }

    return () => {
      if (process.env.NODE_ENV !== "development") {
        window.removeEventListener("blur", onBlur, false);
      }
      window.removeEventListener("orientationchange", updateOrinetation, false);
    };
  }, []);

  //going out of screen/tab
  const onBlur = () => {
    if (currentTab === TABS.LIVE && databaseUniqueUserID != "") {
      if (pageParams.loadWithOptions === "true") {
        // resetStreams();
      } else {
        alert("Your previous session is ended.");
        window.location.reload();
      }
    }
  };

  // changing orientations
  const updateOrinetation = () => {
    if (window.innerWidth > window.innerHeight) setIsPortrait(true);
    else setIsPortrait(false);
  };

  const getTopBarVisibility = () => {
    if (eventData === null) return false;
    if (currentTab === TABS.LIVE || currentTab === TABS.SOCIAL_SEND) {
      if (isPortrait) return true;
      else return false;
    }
    if (currentTab === TABS.LIGHT_SHOW) {
      return false;
    }
    return true;
  };

  restartSession = () => {
    if (onlinePresenceInterval !== null) {
      databaseUniqueUserID = "";
      clearInterval(onlinePresenceInterval);
      onlineSubscriberFactory();
    }
  };

  useEffect(() => {
    if (pageParams.page === TABS.SOCIAL) {
      setSwipeableActiveTabIndex(currentTab === TABS.SOCIAL_FEED ? 0 : 1);
    }
    if (
      eventData === null ||
      usernamePopupVisible === true ||
      userIdFromDB === null
    )
      return;
    if (currentTab === TABS.LIVE) {
      restartSession();
    } else {
      clearInterval(onlinePresenceInterval);
    }
  }, [currentTab]);

  const [fullscreenAdMuted, setFullscreenAdMuted] = useState(true);
  const [showFullscreenAd, setShowFullscreenAd] = useState(false);
  const [userEmail, setUserEmail] = useState(currentUserName);
  function showFullscreenAdToggle() {
    setShowFullscreenAd(!showFullscreenAd);
  }

  let adDuration = 5;
  const [progressValue, setProgressValue] = useState(100);
  useEffect(() => {
    if (showFullscreenAd && postliveAd) {
      if (postliveAd.mimeType.includes("video")) adDuration = 10;
      const timer = setInterval(() => {
        setProgressValue((prevProgress) => {
          if (prevProgress === 20) {
            clearInterval(timer);
            return 0;
          }
          return prevProgress <= 0 ? 100 : prevProgress - 100 / adDuration;
        });
      }, 1000);

      stopCameraFeeds();
      return () => clearInterval(timer);
    }
  }, [showFullscreenAd]);

  const [stopCameraFeed, setStopCameraFeed] = useState(false);
  const stopCameraFeeds = () => {
    setStopCameraFeed(true);
  };

  const [advertsData, setAdvertsData] = useState([]);
  const [swipeableActiveTabIndex, setSwipeableActiveTabIndex] = useState(1);
  async function fetchAndUpdateAds(eventId) {
    try {
      const resp = await uvenuFetcher({
        url: URLS.GET_ADVERTS_FOR_EVENT(eventId),
      });
      if (resp.statusCode === 200 && resp.json) {
        setAdvertsData(resp.json.adverts);
        if (
          resp.json.adverts.filter((a) => a.adType === AD_TYPES.POST_EVENT)
            .length > 0
        ) {
          postliveAd = resp.json.adverts.filter(
            (a) => a.adType == AD_TYPES.POST_EVENT
          )[0];
        }
      } else {
        let error = Error(resp.json.message);
        insights.trackUvenuException(error, {
          info: "JoinEventLive: Error while loading adverts",
          status: "failed",
          response: resp.json,
        });
      }
    } catch (error) {
      insights.trackUvenuException(error, {
        status: "error",
        info: "JoinEventLive: Exception while processing adverts api response",
        error: error,
      });
    }
  }

  const submitUserEmail = async () => {
    if (userEmail.length === 0) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Please enter email to receive rewards",
      });
      return;
    }
    if (!isValidEmail(userEmail)) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Please enter a valid email address",
      });
      return;
    }

    let body = { emailAddress: userEmail, captureSource: AD_TYPES.POST_EVENT };
    await uvenuFetcher({
      url: URLS.SUBMIT_USERS_EMAILS(eventData._id),
      method: "POST",
      body: body,
    });
    window.location.reload();
  };

  const submitLoginEmail = async (name, email, age) => {
    const captureSource = pageParams.page.includes("social")
      ? CAPTURE_SOURCES.SOCIAL_LOGIN
      : pageParams.page === TABS.GAMES
      ? CAPTURE_SOURCES.GAMES_LOGIN
      : pageParams.page === TABS.LIVE
      ? CAPTURE_SOURCES.LIVE_CAM_LOGIN
      : "";
    let body = { emailAddress: email, captureSource, name, age };
    const response = await uvenuFetcher({
      url: URLS.SUBMIT_USERS_EMAILS(eventData._id),
      method: "POST",
      body: body,
    });
  
    if (response && response.statusCode === 201) {
      saveItemToLocalStorage(WEB_APP_LOGIN_KEY, {
        name: name || "",
        age: age || "",
        email: email || "",
      });
    }
  };

  const onContinue = (newName, email, age) => {
    if (currentTab !== TABS.LIGHT_SHOW) {
      localStorage.setItem("web_connect_user_name", newName);
    }

    if (cameraNotLoaded === true) {
      appContext.triggerToast(true, {
        type: "error",
        message: "Camera is required to join, please restart the session.",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    setCurrentUserName(newName);
    setUsernamePopupVisible(false);
    if (email) {
      submitLoginEmail(newName, email, age);
    }
  };

  return (
    <div
      className="mswtRoot"
      style={{
        backgroundColor:
          eventData && eventData.brandingBackgroundColor
            ? eventData.brandingBackgroundColor
            : COLORS.appBackground,
      }}
    >
      {getTopBarVisibility() && (
        <TopBar userName={currentUserName} eventData={eventData} />
      )}
      <div className="mswtMain">
        {loading && (
          <div className="mswtLoaderContainer">
            <Loader />
          </div>
        )}
        <GuestUsernamePopup
          initialUsername={currentUserName}
          modalVisible={usernamePopupVisible}
          event={eventData}
          isLightshow={currentTab === TABS.LIGHT_SHOW}
          page={pageParams.page}
          handleContinue={onContinue}
        />

        {(currentTab === TABS.LIVE || (!usernamePopupVisible && eventData)) &&
        pageParams.page === TABS.SOCIAL ? (
          <SwipeableTabs
            onTabChange={(activeTabIndex) => {
              setCurrentTab(
                activeTabIndex === 0 ? TABS.SOCIAL_FEED : TABS.SOCIAL_SEND
              );
            }}
            activeTabIndex={swipeableActiveTabIndex}
          >
            {eventData && advertsData ? (
              <SocialFeed
                eventData={eventData}
                userId={userIdFromDB}
                advertsData={advertsData}
                isPortrait={isPortrait}
                onSocialFeedScroll={handleScroll}
              />
            ) : null}

            {eventData && advertsData ? (
              <SocialSend
                userId={userIdFromDB}
                userName={currentUserName}
                eventData={eventData}
                loadwithoptions={pageParams.loadWithOptions}
                isPortrait={isPortrait}
                advertsData={advertsData}
              />
            ) : null}
          </SwipeableTabs>
        ) : eventData ? (
          RenderSelectedPage({
            currentTab,
            pageParams,
            eventData,
            userName: currentUserName,
            userIdFromDB,
            usernamePopupVisible,
            isPortrait,
            advertsData,
            stopCameraFeed,
            handleScroll,
          })
        ) : null}
        <div className="mswtPoweredByContainer">
          <img src={powered_by_logo} alt="Uvenu Logo" className="uvenuLogo" />
        </div>
      </div>
      {!pageParams.page || pageParams.page === TABS.SOCIAL ? (
        <div hidden={eventData === null}>
          <BottomTabs
            currentTab={currentTab}
            pageParams={pageParams}
            setCurrentTab={setCurrentTab}
            eventData={eventData}
          />
        </div>
      ) : null}

      {showFullscreenAd && postliveAd && (
        <div
          className="fullscreenAdContainer"
          style={{
            transform: !isPortrait && "rotate(-90deg)",
            transformOrigin: !isPortrait && "center",
            height: !isPortrait && "100vw",
            width: !isPortrait && "100vh",
            marginLeft: !isPortrait && "250px",
            marginTop: !isPortrait && "-250px",
          }}
          onClick={() => {
            if (postliveAd.adLink && postliveAd.adLink.length > 0) {
              window.open(formatHyperlink(postliveAd.adLink));
              return;
            }
          }}
        >
          {postliveAd.mimeType.includes("video") ? (
            <>
              <video
                className="fullscreenVideoAd"
                style={{ objectFit: "fill", filter: "blur(10px)" }}
                src={postliveAd.adMediaUrl}
                autoPlay
                playsInline
                muted
                loop
                disableRemotePlayback={true}
              />
              <video
                className="fullscreenVideoAd"
                style={{ position: "absolute", top: 0, left: 0, zIndex: 2 }}
                src={postliveAd.adMediaUrl}
                autoPlay
                playsInline
                muted={fullscreenAdMuted}
                loop
                disableRemotePlayback={true}
              />
              <img
                className="fullscreenAdMuteIcon"
                src={fullscreenAdMuted ? unmuted : muted}
                alt="mute"
                onClick={(e) => {
                  setFullscreenAdMuted(!fullscreenAdMuted);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </>
          ) : (
            <>
              <img
                className="fullscreenImageAd"
                style={{ objectFit: "fill", filter: "blur(10px)" }}
                src={postliveAd.adMediaUrl}
              />
              <img
                className="fullscreenImageAd"
                style={{ position: "absolute", top: 0, left: 0, zIndex: 2 }}
                src={postliveAd.adMediaUrl}
              />
            </>
          )}
          {eventData.collectContactInfoAt.includes(AD_TYPES.POST_EVENT) && (
            <div
              className="fullscreenEmailContainer"
              style={{
                top:
                  getFullscreenAdPosition(eventData.emailEntryConfig) === "top"
                    ? "10%"
                    : getFullscreenAdPosition(eventData.emailEntryConfig) ===
                      "center"
                    ? "calc(50% - 90px)"
                    : "auto",
                bottom:
                  getFullscreenAdPosition(eventData.emailEntryConfig) ===
                  "bottom"
                    ? "10%"
                    : "auto",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Typography className="fullscreenEmailText">
                {getFullscreenAdHeaderText(eventData.emailEntryConfig)}
              </Typography>
              <TextField
                className="fullscreenEmailTextField"
                variant="filled"
                onInput={(e) => {}}
                defaultValue={userEmail}
                autoFocus
                margin="dense"
                id="email"
                placeholder="enter email address here"
                hiddenLabel
                InputLabelProps={{ style: { color: "white" } }}
                InputProps={{ style: { color: "white" } }}
                fullWidth
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
              {getFullscreenAdSubHeaderText(eventData.emailEntryConfig).length >
                0 && (
                <Typography className="fullscreenAdinfoText">
                  {getFullscreenAdSubHeaderText(eventData.emailEntryConfig)}
                </Typography>
              )}
              <Button
                className="fullscreenAdButton"
                variant="contained"
                onClick={submitUserEmail}
              >
                Sign up
              </Button>
            </div>
          )}
          <CircularProgress
            className="closeProgress"
            variant="determinate"
            value={progressValue}
            size={30}
            style={{
              backgroundColor:
                progressValue === 0 ? "transparent" : "rgba(0,0,0,0.4)",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              console.log(progressValue);
              if (progressValue === 0) window.location.reload();
            }}
          />
          <div className="closeButton">
            <Typography fontWeight={"bold"}>X</Typography>
          </div>
        </div>
      )}
    </div>
  );
}
