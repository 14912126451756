import React, { useEffect, useState, useContext } from "react";
import "./styles.css";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";

import {
  Grid,
  Button,
  Tooltip,
  Select,
  MenuItem,
  Card,
  Typography,
} from "@mui/material";
import {
  Delete,
  Download,
  Refresh,
  ResetTvOutlined,
} from "@mui/icons-material";
import Loader from "../Social/Loader_social";
import { format } from "date-fns";
import { AD_TYPES } from "../AdsSetupManager/functions";

import * as XLSX from "xlsx";
import { uvenuFetcher } from "../../utils/uvenu-fetcher";
import { CAPTURE_SOURCES, URLS } from "../../constants";
import { COLORS } from "../../utils/colors";
import ConfirmationDialog from "../../components/UI/ConfirmationDialog";
import { deleteEventContacts } from "../Games/networkCalls/networkCalls";
import { AppContext } from "../../contexts/App.Context";
import UserDataTable from "./UserDataTable";

function UserEmailsDashboard(props) {
  const loggedInUserObj = props.initialState.loggedInUserObj;
  const [loading, setLoading] = useState(false);
  const [userEmailsData, setUserEmailsData] = useState([]);
  const appContext = useContext(AppContext);
  const options = [
    { value: 0, name: "All", type: "all" },
    { value: 1, name: "Post Event", type: AD_TYPES.POST_EVENT },
    { value: 2, name: "Post Game", type: AD_TYPES.POST_GAME },
    { value: 3, name: "Social Login", type: CAPTURE_SOURCES.SOCIAL_LOGIN },
    { value: 4, name: "Games Polls Login", type: CAPTURE_SOURCES.GAMES_LOGIN },
    { value: 5, name: "Live Cam Login", type: CAPTURE_SOURCES.LIVE_CAM_LOGIN },
  ];
  const [selectedOption, setSelectedOption] = useState(0);
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  const resetData = async () => {
    setShowResetConfirmation(false);
    await deleteEventContacts(
      props.event._id,
      props.initialState.loggedInUserObj
    );
    appContext.triggerToast(true, {
      type: "success",
      message: "Event matrics reset successfully.",
    });

    await refreshData();
  };

  const refreshData = async (option = selectedOption) => {
    setLoading(true);
    try {
      const queryParams =
        option !== 0 ? "captureSource=" + options[option].type : "";
      const url = URLS.SUBMIT_USERS_EMAILS(props.event._id) + "?" + queryParams;
      const response = await uvenuFetcher({
        method: "GET",
        url,
        headers: {
          token: `${loggedInUserObj.token}`,
        },
      });
      if (response.statusCode === 200) {
        setLoading(false);
        setUserEmailsData(response.json.contacts);
      } else {
        setLoading(false);
        alert(response.json.message);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const onOptionChange = (option) => {
    setUserEmailsData([]);
    setSelectedOption(option.target.value);
    refreshData(option.target.value);
  };

  const exportData = () => {
    let exportData = [];
    userEmailsData.map((item) => {
      const source = options.filter(
        (option) => option.type === item.captureSource
      )[0].name;
      const saveDate = format(
        new Date(item.saveDate * 1000),
        "dd MMM yyyy hh:mm a"
      );
      let user = {
        "Email Address": item.emailAddress,
        Name: item.name,
        Age: item.age || "Not Available",
        "Capture Source": source,
        "Submitted On": saveDate,
      };
      exportData.push(user);
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const filename = props.event.name.replace(" ", "") + "-user_emails.xlsx";
    XLSX.writeFile(workbook, filename);
  };

  return (
    <>
      <div className="userEmailsRoot">
        {loading && (
          <div className="userEmailsOverlay">
            <Loader />
          </div>
        )}
        <div className="userEmailsHeader">
          <Select
            className="userEmailsOptionsDropdown"
            sx={{ color: "#0D1051" }}
            value={selectedOption}
            id="effect-select"
            onChange={onOptionChange}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Grid container className="userEmailsContainer" spacing={1}>
          <UserDataTable data={userEmailsData} />
        </Grid>

        <hr style={{ width: "100%" }} />
        <div className="userEmailsBottomButtons">
          <Tooltip title="Refresh data">
            <Button
              className="tabButton"
              variant="outlined"
              onClick={() => {
                setShowResetConfirmation(true);
              }}
              startIcon={<Delete />}
            >
              Reset Data
            </Button>
          </Tooltip>
          <Tooltip title="Refresh data">
            <Button
              className="tabButton"
              sx={{
                color: COLORS.textHeader,
                backgroundColor: COLORS.primary,
                borderColor: COLORS.primary,
                "&:hover": {
                  backgroundColor: COLORS.sideBarBackground,
                },
              }}
              onClick={() => refreshData()}
              startIcon={<Refresh />}
            >
              Refresh
            </Button>
          </Tooltip>
          <Tooltip title="Export data">
            <Button
              className="tabButton"
              sx={{
                color: COLORS.textHeader,
                backgroundColor: COLORS.primary,
                borderColor: COLORS.primary,
                "&:hover": {
                  backgroundColor: COLORS.sideBarBackground,
                },
              }}
              onClick={() => exportData()}
              endIcon={<Download />}
            >
              Export
            </Button>
          </Tooltip>
        </div>
      </div>
      <ConfirmationDialog
        title="Delete Confirmation"
        confirmButtonText="Yes Delete"
        message={`Are you sure you want to delete all the metrics data for this event?`}
        show={showResetConfirmation}
        onConfirm={resetData}
        onClose={() => {
          setShowResetConfirmation(false);
        }}
      />
    </>
  );
}

export default connect(
  (state) => ({ initialState: state.initialState }),
  actions
)(UserEmailsDashboard);
