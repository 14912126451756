import React, { useContext, useEffect, useRef, useState } from "react";
import {
  gameSubmit,
  getGameStats,
} from "../../Games/networkCalls/networkCalls";
import Loader from "../../../components/Loader/Loader";
import "./styles.css";
import { Button, CircularProgress } from "@mui/material";
import {
  formatPercentage,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "../../../utils/utility";
import { Typography } from "@mui/material";

import { AD_TYPES } from "../../AdsSetupManager/functions";
import {
  GAME_AD_EMAIL_SUBMITTED_KEY,
  SUBMITTED_GAME_IDS_KEY,
} from "../../../constants";

import { AppContext } from "../../../contexts/App.Context";
import PostGameAd from "./PostGameAd";
export function Game(props) {
  const appContext = useContext(AppContext);
  const { eventData, userId, advertsData, activeGame, setShowUserInfoPopup } =
    props;
  const [loading, setLoading] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [gameWon, setGameWon] = useState(null);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [isTimedGame, setIsTimedGame] = useState(false);
  const [timeValue, setTimeValue] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [hasAlreadySubmitted, setHasAlreadySubmitted] = useState(null);
  const [postGameAd, setPostGameAd] = useState(null);
  const [gameStats, setGameStats] = useState(null);
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  let enteredEmail = useRef("");

  useEffect(() => {
    if (advertsData === null || advertsData === undefined) return;
    let newAdverts = advertsData.filter((a) => a.adType === AD_TYPES.POST_GAME);
    if (newAdverts.length > 0) setPostGameAd(newAdverts[0]);
  }, [advertsData]);
  const isAlreadySubmitted = () => {
    const savedGamesStateArray = getItemFromLocalStorage(
      SUBMITTED_GAME_IDS_KEY
    );
    if (savedGamesStateArray) {
      const thisGame = savedGamesStateArray.find(
        (game) => game.gameId === activeGame._id
      );
      if (thisGame) {
        // This game has been played at least once

        if (thisGame.lastResetTime) {
          // Game has been reset by the admin at least once
          if (thisGame.lastResetTime === activeGame?.resetAt) {
            // Game has been reset by the admin and the user has played the game
            return true;
          } else {
            // Game has been reset by the admin and the user has not played the game post this reset time

            return false;
          }
        } else {
          if (activeGame?.resetAt) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    }

    return false;
  };
  useEffect(() => {
    if (activeGame) {
      const isSubmitted = isAlreadySubmitted();
      if (isSubmitted) {
        onPollSubmit();
        setGameFinished(true);
      }
      setHasAlreadySubmitted(isSubmitted);
      if (activeGame.isTimed === true && activeGame.timedSeconds !== "0") {
        setIsTimedGame(true);
        setTimeValue(activeGame.timedSeconds);
      }
    }
  }, [activeGame]);

  const handleActivateGame = () => {
    if (activeGame) {
      if (!hasAlreadySubmitted) {
        setGameStarted(true);
        if (isTimedGame) {
          startTimer();
        }
      }
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setCorrectAnswer(activeGame.correctAnswerText); // Set the correct answer
  };

  const handleSubmitResponse = async () => {
    if (!selectedOption) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Please choose an answer",
      });
      return;
    }
    try {
      setSubmittingAnswer(true);
      const submitResponse = await gameSubmit(
        {
          gameId: activeGame._id,
          userId,
          answer: selectedOption.optionText,
        },
        activeGame?.resetAt
      );
      setSubmittingAnswer(false);
      if (activeGame.correctAnswerText === selectedOption.optionText) {
        setGameWon(true);
      } else {
        setGameWon(false);
      }
      if (selectedOption.optionText === correctAnswer) {
        setIsAnswerCorrect(true); // Selected answer is correct
      } else {
        setIsAnswerCorrect(false); // Selected answer is incorrect
      }
      setAnswerSubmitted(true);
      if (isTimedGame && timeLeft !== 0) {
        // Time is still remaining, do not proceed
        return;
      }
      setGameFinished(true);
    } catch (error) {
      console.log("Error submitting game answer:", error);
      // Handle the error as needed
    }

    // Use the submitResponse value here or further process it if necessary
  };

  const startTimer = () => {
    setTimeLeft(timeValue); // Set the initial time value
  };

  const stopTimer = () => {
    setTimeLeft(null);
  };

  // Update the timer every second
  useEffect(() => {
    let timer;
    if (timeLeft !== null && timeLeft > 0) {
      timer = setTimeout(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      // Handle case when time is up
      if (answerSubmitted) {
        if (selectedOption) {
          handleSubmitResponse();
          return;
        }
      }
      setGameWon(false);
      setIsAnswerCorrect(false);
      setAnswerSubmitted(true);
      setGameFinished(true);
    }
    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [timeLeft]);

  // Start the timer when the game starts
  useEffect(() => {
    if (gameStarted && isTimedGame) {
      startTimer();
    }
  }, [gameStarted, isTimedGame]);
  const [isFetchingStats, setIsFetchingStats] = useState(false);
  async function fetchGameStats() {
    setIsFetchingStats(true);
    const response = await getGameStats(activeGame._id);
    setIsFetchingStats(false);
    setGameStats(response);
  }

  const onPollSubmit = async () => {
    await fetchGameStats();
  };
  useEffect(() => {
    if (gameFinished && activeGame.gameType === "2") {
      onPollSubmit();
    }
  }, [gameFinished, activeGame.gameType]);

  const handleGameOver = () => {
    if (isTimedGame) {
      if (timeLeft !== 0) {
        // Time is still remaining, do not proceed
        return;
      }
    }
    setGameFinished(true);
  };

  const goToHome = async () => {
    window.location.reload();
  };

  useEffect(() => {
    saveItemToLocalStorage(`${GAME_AD_EMAIL_SUBMITTED_KEY}-${eventData._id}`, {
      isSubmitted: hasAlreadySubmitted,
    });
  }, [hasAlreadySubmitted]);

  useEffect(() => {
    if (
      gameFinished &&
      postGameAd &&
      !hasAlreadySubmitted &&
      !isEmailAlreadySubmitted()
    ) {
      setShowUserInfoPopup(true);
    }
  }, [gameFinished]);
  const isEmailAlreadySubmitted = () => {
    const isSubmitted = getItemFromLocalStorage(
      `${GAME_AD_EMAIL_SUBMITTED_KEY}-${eventData._id}`
    );
    if (isSubmitted) {
      return isSubmitted.isSubmitted;
    }
    return false;
  };
  const renderPosterAndQuestion = (activeGame) => {
    return (
      <>
        <div className="jelgPosterContainer skeleton">
          {gameFinished && postGameAd ? (
            <PostGameAd
              postGameAd={postGameAd}
              enteredEmail={enteredEmail}
              eventData={eventData}
              setShowPostGameEmailInput={setShowUserInfoPopup}
            />
          ) : (
            <img
              src={activeGame && activeGame.gameImageUrl}
              alt="Event poster"
              className="jelgImage"
            />
          )}
        </div>

        <div className="questionContainer">
          <p className="questionText">
            {activeGame && activeGame.questionText}
          </p>
        </div>
      </>
    );
  };

  const renderTimer = () => {
    return isTimedGame ? (
      <div className="timerContainer">
        {timeLeft !== null && timeLeft > 0 ? (
          <>
            <CircularProgress
              variant="determinate"
              value={(timeLeft / timeValue) * 100}
              size={30}
              thickness={4}
              color="secondary"
            />
            <div className="timerOverlay">
              <p className="timerText">{timeLeft}</p>
              {/* <p className="timerSubtitle">seconds left</p> */}
            </div>
          </>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </div>
    ) : null;
  };

  const getGameResultButtonBody = (option) => {
    const isCorrectAnswer = activeGame.correctAnswerText === option.optionText;

    return (
      <>
        <span style={{ zIndex: 1, position: "relative" }}>
          {option.optionText}
        </span>
        {isCorrectAnswer ? (
          <span
            className="correct-answer-btn"
            style={{
              width: "100%",
              position: "absolute",
              inset: 0,
            }}
          ></span>
        ) : null}
      </>
    );
  };

  const getButtonBody = (option) => {
    if (gameFinished && gameStats) {
      if (activeGame.gameType === "2") {
        // Poll
        const answer = gameStats.answerStats.find(
          (ans) => ans.answer.optionText === option.optionText
        );
        return answer ? (
          <>
            <span
              className="percentSpan"
              style={{
                width: `${answer.percentage}%`,
              }}
            ></span>

            <span style={{ position: "relative", zIndex: 1 }}>
              {answer.answer.optionText}
            </span>
            <span
              style={{
                position: "absolute",
                right: "20px",
                zIndex: 1,
                display: "flex",
              }}
            >
              {isFetchingStats ? (
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: "white",
                  }}
                  size={24}
                  thickness={8}
                  value={100}
                />
              ) : (
                `${formatPercentage(answer.percentage)}%`
              )}
            </span>
          </>
        ) : null;
      } else {
        // Game
        return getGameResultButtonBody(option);
      }
    }
    return option.optionText;
  };

  const renderBody = (activeGame) => {
    return gameStarted || gameFinished ? (
      <div className="optionsContainer">
        {activeGame?.gameType === "2" && gameFinished && !activeGame?.isLive
          ? null
          : activeGame.answerOptions.map((option) => (
              <Button
                variant="outlined"
                size="large"
                fullWidth
                key={option._id}
                className={`web-app-game-option ${
                  gameStats ? " result-variant" : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {submittingAnswer ? (
                  <CircularProgress
                    variant="determinate"
                    sx={{
                      color: "white",
                    }}
                    size={24}
                    thickness={8}
                    value={100}
                  />
                ) : (
                  getButtonBody(option)
                )}
              </Button>
            ))}
      </div>
    ) : (
      <Typography className="textBelowImage gameTitle">
        {activeGame && activeGame.gameTitle}
      </Typography>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="jelgMainContainer">
          {" "}
          {activeGame ? (
            <>
              <div className="gameContainer">
                {renderPosterAndQuestion(activeGame)}
                {renderTimer()}
                {renderBody(activeGame)}
              </div>

              {hasAlreadySubmitted && (
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.6)",
                    alignSelf: "center",
                    padding: "8px 0px",
                    textAlign: "center",
                  }}
                >
                  You have already submitted your answer.
                </span>
              )}
              {gameFinished &&
                (isAnswerCorrect ? (
                  <Typography className="textBelowImage">
                    {activeGame.postGameWinningMessage}
                  </Typography>
                ) : (
                  <Typography className="textBelowImage">
                    {activeGame.postGameLosingMessage}
                  </Typography>
                ))}
            </>
          ) : (
            <span
              style={{ marginTop: "16px", color: "white", alignSelf: "center" }}
            >
              No active game available!
            </span>
          )}
          <div className="jelgButtonContainer">
            {activeGame && gameStarted && !gameFinished ? (
              <>
                {answerSubmitted ? (
                  <Button
                    variant="contained"
                    onClick={handleGameOver}
                    className="grey-button"
                    disabled={isTimedGame && timeLeft !== 0}
                  >
                    {timeLeft === 0 || timeLeft === null
                      ? "CONTINUE"
                      : "Waiting for timer..."}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleSubmitResponse}
                    className="grey-button"
                  >
                    {submittingAnswer ? (
                      <CircularProgress
                        variant="determinate"
                        sx={{
                          color: "white",
                        }}
                        size={24}
                        thickness={8}
                        value={100}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                )}
              </>
            ) : gameFinished ? (
              postGameAd &&
              eventData.collectContactInfoAt.includes(
                AD_TYPES.POST_GAME
              ) ? null : (
                <Button
                  variant="contained"
                  onClick={goToHome}
                  className="grey-button"
                  style={{ textTransform: "uppercase" }}
                >
                  Go home
                </Button>
              )
            ) : hasAlreadySubmitted ? null : (
              <Button
                variant="contained"
                onClick={handleActivateGame}
                className="grey-button"
              >
                {activeGame ? "JOIN" : "RETRY"}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
