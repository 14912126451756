import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Paper,
} from "@mui/material";
import { COLORS } from "../../utils/colors";
const ConfirmationDialog = ({
  title,
  message,
  onConfirm,
  show,
  confirmButtonText,
  onClose,
  children,
  headerStyle,
}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  useEffect(() => {
    setShowConfirmationDialog(show);
  }, [show]);
  return (
    <Dialog
      open={showConfirmationDialog}
      onClose={() => {
        setShowConfirmationDialog(false);
        onClose();
      }}
    >
      <Paper style={{ backgroundColor: COLORS.popupBackground }}>
        <DialogTitle
          style={
            headerStyle
              ? { color: COLORS.textHeader, ...headerStyle }
              : { color: COLORS.textHeader }
          }
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {message && (
            <DialogContentText style={{ color: COLORS.textHeader }}>
              {message}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirm}>{confirmButtonText}</Button>
          <Button
            onClick={() => {
              setShowConfirmationDialog(false);
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};
export default ConfirmationDialog;
