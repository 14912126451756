import React, { useEffect, useRef, useState, useContext } from "react";
import { getActiveGameData } from "../../Games/networkCalls/networkCalls";
import generateUserid from "../../../utils/generateUserid";
import "./styles.css";

import { Game } from "./Game";
import "./styles.css";
import { motion, useScroll } from "framer-motion";
import { Button, TextField, Typography } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { GAME_AD_EMAIL_SUBMITTED_KEY, URLS } from "../../../constants";
import {
  getItemFromLocalStorage,
  isValidEmail,
  saveItemToLocalStorage,
} from "../../../utils/utility";
import {
  AD_TYPES,
  getFullscreenAdHeaderText,
  getFullscreenAdSubHeaderText,
} from "../../AdsSetupManager/functions";
import { uvenuFetcher } from "../../../utils/uvenu-fetcher";
import { AppContext } from "../../../contexts/App.Context";
import ConfirmationDialog from "../../../components/UI/ConfirmationDialog";
export function GamesTab(props) {
  const { pageParams, eventData, userId } = props;
  const [activeGames, setActiveGames] = useState([]);
  const PADDING = 16;
  let userID = useRef(userId);

  const ref = useRef(null);

  const cardRef = useRef(null);
  const { scrollXProgress } = useScroll({ container: ref });
  const [showPreviousArrow, setShowPreviousArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(activeGames.length > 1);
  const [showUserInfoPopup, setShowUserInfoPopup] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (userID.current === "" || userID.current === null) {
      const databaseUniqueUserID = localStorage.getItem("databaseUniqueUserID");
      if (databaseUniqueUserID) {
        userID.current = databaseUniqueUserID;
      } else {
        userID.current = generateUserid();
      }
    }
    if (pageParams.username !== null && pageParams.username.length > 0)
      userID.current = generateUserid(true);
    fetchActiveGame();
  }, []);

  async function fetchActiveGame() {
    try {
      const gameData = await getActiveGameData(eventData._id, userID.current);
      if (gameData.statusCode === 200) {
        if (gameData.json.games.length > 1) {
          setShowNextArrow(true);
        }
        setActiveGames(gameData.json.games);
      }
    } catch (error) {
      console.log("Error fetching active game:", error);
    }
  }

  useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollLeft < 20) {
        setShowPreviousArrow(false);
      } else {
        setShowPreviousArrow(true);
      }
      if (
        ref.current.scrollLeft >=
        ref.current.scrollWidth - ref.current.offsetWidth - 20
      ) {
        setShowNextArrow(false);
      } else {
        setShowNextArrow(true);
      }
    };
    if (ref.current) {
      ref.current.addEventListener("scroll", onScroll);
    }
    return () => {
      if (ref.current) ref.current.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onClickPrevious = () => {
    const card = document.getElementsByClassName("game-card")[0];
    const body = document.getElementsByTagName("body")[0].offsetWidth;
    let cardWidth = body.offsetWidth - PADDING;
    if (card) {
      cardWidth = card.offsetWidth + PADDING; // 16 is the padding of container
    }
    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft - cardWidth,
        behavior: "smooth", // Adds smooth scrolling animation
      });
    }
    if (ref.current.scrollLeft < cardWidth + 20) {
      setShowPreviousArrow(false);
    }
    if (ref.current.scrollLeft >= cardWidth) {
      setShowNextArrow(true);
    }
  };
  const onClickNext = () => {
    const card = document.getElementsByClassName("game-card")[0];
    const body = document.getElementsByTagName("body")[0].offsetWidth;
    let cardWidth = body.offsetWidth - PADDING;
    if (card) {
      cardWidth = card.offsetWidth + 16; // 16 is the padding of container
    }

    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft + cardWidth,
        behavior: "smooth", // Adds smooth scrolling animation
      });
    }
    if (ref.current.scrollLeft > 0) {
      setShowPreviousArrow(true);
    } else {
      if (activeGames.length > 1) {
        setShowPreviousArrow(true);
      } else {
        setShowPreviousArrow(false);
      }
    }
    if (ref.current.scrollLeft >= cardWidth * (activeGames.length - 2)) {
      setShowNextArrow(false);
    } else {
      setShowNextArrow(true);
    }
  };

  const handleUserInfoSubmit = async () => {
    if (userEmail.length === 0) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Please enter email to receive rewards",
      });
      return;
    }

    if (!isValidEmail(userEmail)) {
      appContext.triggerToast(true, {
        type: "warning",
        message: "Please enter a valid email address",
      });
      return;
    }
    let body = {
      emailAddress: userEmail,
      captureSource: AD_TYPES.POST_GAME,
    };
    await uvenuFetcher({
      url: URLS.SUBMIT_USERS_EMAILS(eventData._id),
      method: "POST",
      body: body,
    });
    saveItemToLocalStorage(`${GAME_AD_EMAIL_SUBMITTED_KEY}-${eventData._id}`, {
      isSubmitted: true,
    });
    //setIsEmailSubmitted(true);
    setShowUserInfoPopup(false);
  };
  return (
    <div className="multiple-games-root">
      <div className="game-cards-container" ref={ref}>
        {activeGames.length ? (
          activeGames.map((game) => (
            <div key={game._id} ref={cardRef} className="jelgRoot game-card">
              <Game
                activeGame={game}
                {...props}
                userId={userID.current}
                setShowUserInfoPopup={setShowUserInfoPopup}
              />
            </div>
          ))
        ) : (
          <div
            className="jelgRoot game-card"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            No Active Game / Poll.
          </div>
        )}
      </div>
      <div className="cardNavigationContainer">
        <Button
          className="navigation-button"
          title="Previous"
          onClick={onClickPrevious}
          disabled={!showPreviousArrow}
          startIcon={<ArrowBackIosNew />}
        >
          Previous
        </Button>

        <svg id="progress" width="80" height="80" viewBox="0 0 80 80">
          <circle cx="40" cy="40" r="20" pathLength="1" className="bg" />
          <motion.circle
            cx="40"
            cy="40"
            r="20"
            pathLength="1"
            className="indicator"
            style={{ pathLength: scrollXProgress }}
          />
        </svg>

        <Button
          className="navigation-button"
          title="Next"
          onClick={onClickNext}
          disabled={!showNextArrow}
          endIcon={<ArrowForwardIos />}
        >
          Next
        </Button>
      </div>
      <ConfirmationDialog
        title={getFullscreenAdHeaderText(eventData?.emailEntryConfigForGames)}
        confirmButtonText="Submit"
        onConfirm={handleUserInfoSubmit}
        onClose={() => {
          setShowUserInfoPopup(false);
        }}
        show={showUserInfoPopup}
        headerStyle={{ textAlign: "center" }}
      >
        <TextField
          size="small"
          style={{ width: "100%" }}
          variant="outlined"
          defaultValue={userEmail}
          margin="dense"
          id="email"
          label="Email Address"
          onChange={(e) => {
            setUserEmail(e.target.value);
          }}
          inputProps={{
            style: {
              color: "white",
            },
          }}
        />

        {getFullscreenAdSubHeaderText(eventData?.emailEntryConfigForGames)
          .length > 0 && (
          <Typography
            style={{ marginBottom: 0, textAlign: "center", color: "white" }}
          >
            {getFullscreenAdSubHeaderText(eventData?.emailEntryConfigForGames)}
          </Typography>
        )}
      </ConfirmationDialog>
    </div>
  );
}
