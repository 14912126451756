import { useState } from "react";

import { Link } from "@mui/material";
import muted from "../../../../src/assets/muted.png";
import unmuted from "../../../../src/assets/unmuted.png";

import { formatHyperlink } from "../../../utils/utility";
const PostGameAd = ({ postGameAd }) => {
  const [postGameAdMuted, setPostGameAdMuted] = useState(true);

  return (
    <div className="postGameAd">
      <Link
        href={formatHyperlink(postGameAd.adLink)}
        target="_blank"
        underline="hover"
        style={{ width: "100%" }}
      >
        <div
          style={{ width: "100%", aspectRatio: "16/9" }}
          className="skeleton"
        >
          {" "}
          {postGameAd.mimeType.includes("video") ? (
            <>
              <video
                className="videoAd"
                src={postGameAd.adMediaUrl}
                autoPlay
                playsInline
                loop
                muted={postGameAdMuted}
                disableRemotePlayback={true}
              />

              <img
                style={{
                  width: 30,
                  height: 30,
                }}
                src={postGameAdMuted ? unmuted : muted}
                alt="mute"
                className="jelgMuteIcon"
                onClick={(e) => {
                  setPostGameAdMuted(!postGameAdMuted);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </>
          ) : (
            <img
              src={postGameAd.adMediaUrl}
              alt="social ad media"
              className="imageAd"
            />
          )}
        </div>

        <div className="jelgActionBar">
          <span className="adTitle">
            {postGameAd.adTitle ? postGameAd.adTitle : "Sponsored Ad"}
          </span>
          <span className="adAction">{">"}</span>
        </div>
      </Link>
    </div>
  );
};
export default PostGameAd;
